import React, { ChangeEvent, useState } from "react";

import { SanityNews } from "@graphql-types";
import { Container, GridContainer, PageWidth, Section } from "@util/standard";
import NewsPreview from "./preview";
import { Button } from "@global";
import { useStore } from "@state/store";
import { NO_SEARCH_RESULTS_MESSAGE } from "@util/constants";
import { SelectOption } from "@util/types";
import { sortArticles } from "@util/helper";
import { DropdownContainer } from "@styles/formStyles";

interface Props {
  data: SanityNews[];
  displayHits?: boolean;
}

export default function NewsGrid({ data, displayHits }: Props) {
  const [slice, setSlice] = useState(6);
  const [sortValue, setSortValue] = useState<SelectOption>();
  const sortedArticles = sortArticles(data, sortValue?.title ?? "");
  const newsToShow = sortedArticles.slice(0, slice);
  const { pageColor } = useStore();

  const handleLoadMore = () => setSlice(slice + 6);

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target?.value) {
      setSortValue({ id: e.target.value, title: e.target.value });
    }
  };

  return (
    <Section aria-label="news" id="news-hits">
      <PageWidth mobileFullWidth>
        <Container
          flexDirection="column"
          tabletWidth="80%"
          margin="0 0 20px 0"
          mobileMargin="auto auto 20px auto"
          justifyContent="space-between"
        >
          {displayHits && Boolean(data?.length) && <p>{`${data?.length} search results`}</p>}
          {displayHits && !Boolean(data?.length) && <h4>{NO_SEARCH_RESULTS_MESSAGE}</h4>}
          <Container maxWidth="200px" width="100%">
            <DropdownContainer onChange={handleChange} defaultValue="Latest" noLabel>
              <option value="Latest">Latest</option>
              <option value="Oldest">Oldest</option>
            </DropdownContainer>
          </Container>
        </Container>
        <GridContainer rowGap={60} columnGap={20}>
          {newsToShow.map((news, index) => {
            if (news == null) return null;
            return <NewsPreview key={index} data={news} />;
          })}
        </GridContainer>
      </PageWidth>
      <Container margin="60px 0 0 0" width="100%" justifyContent="center">
        {newsToShow.length < data.length && (
          <Button
            theme="colored"
            colorvalue={pageColor}
            onClick={handleLoadMore}
            aria-label="Load more articles"
          >
            Load More
          </Button>
        )}
      </Container>
    </Section>
  );
}
