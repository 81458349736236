import React, { useEffect } from "react";
import { InstantSearch, Index, connectHits, Configure } from "react-instantsearch-dom";
import styled from "styled-components";

import { Container, CreamCardContainer, PageWidth, Section } from "@util/standard";
import { algoliaSearchClient } from "@util/helper";
import { useStore } from "@state/store";
import { Maybe, SanityContactCta, SanityNews } from "@graphql-types";
import NewsGrid from "@components/news/newsGrid";
import NewsFilters from "@components/news/newsFilters";
import { mediaQuery } from "@util/constants";

interface Props {
  defaultData?: SanityNews[];
  mediaEnquires?: Maybe<SanityContactCta> | undefined;
}

export default function NewsSearchWrapper({ defaultData, mediaEnquires }: Props) {
  const { clearSearchQuery } = useStore();

  useEffect(() => {
    return () => clearSearchQuery();
  }, []);

  return (
    <Section>
      <InstantSearch indexName="news" searchClient={algoliaSearchClient}>
        <Configure hitsPerPage={1000} />
        <Index indexName="news">
          <MediaEnquiries mediaEnquires={mediaEnquires} />
          <NewsFilters />
          <CustomNewsSearchHits defaultData={defaultData} />
        </Index>
      </InstantSearch>
    </Section>
  );
}

interface HitsProps extends Props {
  hits: SanityNews[];
}

function NewsHits(props: HitsProps) {
  const { hits, defaultData } = props;
  const { searchQuery, searchFacets } = useStore();

  if (Boolean(searchQuery?.length || searchFacets.length)) {
    return <NewsGrid displayHits data={hits} />;
  }

  if (defaultData) return <NewsGrid data={defaultData} />;

  return null;
}

export const CustomNewsSearchHits: React.ComponentClass<any, Props> = connectHits(NewsHits);

const MediaEnquiries = ({ mediaEnquires }: Partial<Props>) => {
  if (mediaEnquires == null) return null;
  const { title, contactName, phone, email } = mediaEnquires;

  return (
    <PageWidth>
      <CreamCardContainer>
        <StyledContainer>
          {title && <p className="h3">{title}</p>}
          {contactName}
          <a href={`mailto:${email}`}>{`Email: ${email}`}</a>
          <a href={`tel:${phone}`}>{`For urgent media enquiries phone ${phone}`}</a>
        </StyledContainer>
      </CreamCardContainer>
    </PageWidth>
  );
};

const StyledContainer = styled(Container)`
  justify-content: space-between;
  align-items: center;

  ${mediaQuery.tabletDown} {
    align-items: flex-start;
    flex-direction: column;
    row-gap: 20px;
  }
`;
