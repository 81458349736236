import React from "react";

import { Button } from "@global";
import { Container, PageWidth } from "@util/standard";
import { useToggle } from "@util/hooks";
import { useStore } from "@state/store";
import { CustomRefinementList, CustomClearRefinements } from "@components/search/refinement";
import { useUrlSearchParamForRefinement } from "@util/logicHooks";
import { isBrowser } from "@util/helper";
import { SortBy } from "react-instantsearch-dom";

export default function NewsFilters() {
  const [applyFilters, toggle] = useToggle();
  const { pageColor, searchFacets } = useStore();
  const { searchInput, shouldClear } = useUrlSearchParamForRefinement(toggle);

  const handleClick = () => {
    const resultsContainer = document.getElementById("news-hits");

    if (isBrowser() && resultsContainer) {
      window.scrollTo({ top: resultsContainer.offsetTop - 100, behavior: "smooth" });
    }
    toggle();
  };

  return (
    <Container margin="60px 0">
      <PageWidth>
        <Container margin="20px 0 0 0" columnGap="25px" rowGap="10px" isTabletColumn>
          <CustomRefinementList
            indexName="news"
            listTitle="Category"
            operator="or"
            attribute="categories.title"
            applyFilters={applyFilters}
            shouldClear={shouldClear}
          />
          <CustomRefinementList
            indexName="news"
            listTitle="Village"
            operator="or"
            attribute="villageRef.title"
            applyFilters={applyFilters}
            // defaultRefinement={Boolean(searchInput?.length) ? [searchInput] : undefined}
            shouldClear={shouldClear}
          />
          {/* <SortBy items={[]} defaultRefinement="publish_date_timestamp_asc" /> */}
          {/* <CustomRefinementList
            indexName="news"
            listTitle="Region"
            operator="or"
            attribute="regionRef.title"
            applyFilters={applyFilters}
            shouldClear={shouldClear}
          /> */}

          <Container width="100px" flexDirection="column">
            <Container margin="0 0 10px 0">
              <Button
                theme="colored"
                colorvalue={pageColor}
                onClick={handleClick}
                aria-label="Apply selected filters"
              >
                Apply
              </Button>
            </Container>
            <CustomClearRefinements />
          </Container>
        </Container>
      </PageWidth>
    </Container>
  );
}
