import React from "react";
import { graphql, PageProps } from "gatsby";

import { Query } from "@graphql-types";
import { Blocks, ErrorMsg, Header, SEO, BasicHero } from "@shared";
import { usePageMeta } from "@util/logicHooks";
import NewsSearchWrapper from "@components/search/newsSearchWrapper";

interface Props extends PageProps {
  data: Query;
}

export default function NewsLandingTemplate({
  data: {
    sanityNewsLanding,
    sanityHeader,
    allSanityNews: { nodes },
  },
}: Props) {
  if (sanityNewsLanding == null)
    return <ErrorMsg data={sanityNewsLanding} msg="Error fetching data for page" />;

  const { slug, _type, pageColour, title, seo, blockContent, ctas, blocks, mediaEnquires } =
    sanityNewsLanding;
  usePageMeta(pageColour?.colour?.hex, _type);

  return (
    <div>
      <SEO seoData={seo} slug={slug?.current as string} />
      <Header data={sanityHeader} />
      <BasicHero heading={title} blockContent={blockContent} ctas={ctas} />
      <NewsSearchWrapper defaultData={nodes} mediaEnquires={mediaEnquires} />
      {blocks && <Blocks data={blocks} />}
    </div>
  );
}

export const query = graphql`
  query newsLandingQuery {
    sanityHeader {
      ...sanityHeader
    }
    sanityNewsLanding {
      seo {
        ...sanitySeo
      }
      _type
      title
      mediaEnquires {
        ...sanityContactCta
      }
      blockContent {
        _rawBlockContent
      }
      ctas {
        ...sanityLink
      }
      slug {
        current
      }
      pageColour {
        ...sanityColorPicker
      }
      heroNews {
        ...sanityNewsPreview
      }
      blocks {
        ...staticPageBlocks
      }
    }
    allSanityNews(sort: { order: DESC, fields: publishDate }) {
      nodes {
        ...sanityNewsPreview
      }
    }
  }
`;
